<template>
  <div id="rebate_report">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="title">{{ $t('menu.rebateReport') }}</div>
        <div class="query">
          <div class="flex top">
            <AccountNumber @accountCallback="init"></AccountNumber>
            <SelectTree :accountID="accountID" :chooseNode.sync="chooseNode" />
            <el-cascader
              expand-trigger="hover"
              :options="instrumentOptions"
              v-model="selectedInstrument"
              :show-all-levels="false"
            ></el-cascader>
          </div>
          <div class="flex bottom flex-center select_lbox">
            <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
            <loading-button class="ib-button" :callback="queryRebateReport" text="common.keys.UPDATE"></loading-button>
          </div>

          <!-- 
            <div class="right_box" :class="{ btn_update_small: lang === 'km' }">
              <loading-button
                class="ib-button"
                :callback="queryRebateReport"
                text="common.keys.UPDATE"
              ></loading-button>
            </div> -->

          <ul class="theme_box">
            <li class="box-btn rounded">
              <div class="item_header">
                {{ $t('report.rebate.others.rebate') }}
              </div>

              <div class="content">
                <p>
                  <i>{{ currAccountCurrencyFilter }}</i>
                  {{ totalRebate | currency('') }}
                </p>
                <span>{{ $t('report.rebate.others.totalRebate') }}</span>
              </div>
            </li>
            <template v-for="(summary, index) in summaries">
              <li v-if="showAll ? true : index < 5" class="box-btn rounded" :sm="8" v-bind:key="index">
                <div class="item_header">
                  {{ summary.category }}
                </div>
                <div class="content">
                  <el-col :span="12">
                    <p>{{ summary.volume | formatNumber }}</p>
                    <span>{{ $t('report.rebate.others.lots') }}</span>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <i>{{ currAccountCurrencyFilter }}</i>
                      {{ summary.commission | currency('') }}
                    </p>
                    <span>{{ $t('report.rebate.others.rebates') }}</span>
                  </el-col>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="text-center">
          <el-button class="ib-button mb-5" @click="showAllInstruments">{{
            showAll ? $t('report.btn.hide') : $t('report.btn.showAll')
          }}</el-button>
        </div>
        <div class="content">
          <div class="select download_container item_header">
            <span>
              {{ $t('report.rebate.others.rebate') }} {{ $t('common.keys.FROM') }}
              {{ startDate | formatDate('DD/MM/YYYY') }} {{ $t('common.keys.TO') }}
              {{ endDate | formatDate('DD/MM/YYYY') }}
            </span>
            <loading-button class="ib-button" :callback="downLoad" text="common.keys.DOWNLOAD"></loading-button>
          </div>

          <div class="table_box">
            <el-table
              :data="displayData"
              ref="summaryTable"
              style="width: 100%"
              :default-sort="{ prop: 'totalrebate', order: 'descending' }"
              @sort-change="sortChange"
            >
              <el-table-column :label="$t('report.rebate.column.name')" style="word-wrap: normal" min-width="100">
                <template slot-scope="scope">
                  <a
                    class="text-primary-alt"
                    @click.stop.prevent="
                      dialogVisible = true;
                      queryAllRebateDetails();
                      viewTradeDetail = { name: scope.row.name, mt4Account: scope.row.number };
                    "
                    data-testid="name"
                    >{{ scope.row.name }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column prop="number" :label="$t('report.rebate.column.accountNumber')" min-width="100">
              </el-table-column>
              <el-table-column :label="$t('report.rebate.column.accountType')" min-width="100">
                <template slot-scope="scope">
                  {{ $platform.accountTypeMaps(regulator)[scope.row.type] }}
                </template>
              </el-table-column>

              <el-table-column
                v-for="(nestedField, index) in columns"
                :key="'nested-' + index"
                :label="$t('common.products.' + nestedField.label)"
                :sortable="true"
                :sort-by="`${nestedField.prop}.rebate`"
              >
                <el-table-column :label="$t('report.rebate.column.volume')" min-width="80">
                  <template slot-scope="scope">
                    <span>{{ scope.row[nestedField.prop].volume | formatNumber }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('report.rebate.column.rebate')" min-width="80">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row[nestedField.prop].rebate | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column
                :label="$t('report.rebate.column.totalRebate')"
                sortable
                sort-by="totalrebate"
                :sort-orders="['ascending', 'descending']"
                min-width="100"
              >
                <template slot-scope="scope">
                  <span>{{ currAccountCurrencyFilter }}{{ scope.row.totalrebate | currency('') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></pagination>
        </div>
      </div>
    </div>
    <Dialog :dialogVisible.sync="dialogVisible" :viewTradeDetail="viewTradeDetail"></Dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import LoadingButton from '@/components/LoadingButton';
import DateRangePicker from '@/components/DateRangePicker';
import Dialog from '@/components/rebateReport/Dialog';
import AccountNumber from '@/components/form/AccountNumber';
import { apiRebate_report, apiGet_instruments, apiRebatePayerTradeDetails } from '@/resource';
import moment from 'moment';
import { Message } from 'element-ui';
import ExportJsonExcel from 'js-export-excel';
import SelectTree from '@/components/SelectTree';
export default {
  components: { Pagination, LoadingButton, DateRangePicker, Dialog, AccountNumber, SelectTree },
  data() {
    return {
      startDate: '',
      endDate: '',
      //可能存在修改时间，却没有点刷新的情况，所以要记录一下刷新时间。
      updateTimeRecord: { startDate: this.startDate, endDate: this.endDate },
      userID: this.$store.state.common.CUID,
      dialogVisible: false,
      totalRebate: 0,
      summaries: [],
      viewTradeDetail: { name: null, mt4Account: null },
      selectedInstrument: [null],
      instrumentOptions: [
        {
          value: null,
          label: this.$t('report.rebate.dropdown.allInstruments')
        }
      ],
      showAll: false,
      tableHeaderSeq: [],
      // 分页
      displayData: [],
      sorting: { column: 'totalrebate', order: 'descending' },
      tableData: [],
      currAccountCurrencyFilter: '',
      chooseNode: null
    };
  },
  watch: {
    dataSourceId() {
      this.queryInstruments();
    }
  },
  methods: {
    async downLoad() {
      //没有数据是下载功能return
      if (!this.tableData.length) {
        Message({
          message: this.$t('report.nodataMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }
      let days = moment(this.endDate).diff(moment(this.startDate), 'days');
      // 允许下载最大天数
      const MAX_DAYS = 90;
      const fileName = `rebate_report_${moment(this.updateTimeRecord.startDate).format('YYYY-MM-DD')}_${moment(
        this.updateTimeRecord.endDate
      ).format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD HH:mm:ss A')}`;
      if (days > MAX_DAYS) {
        Message({
          message: this.$t('report.timeLimitMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }

      //表头字段
      const rebateHeader = this.generateDynamicHeader(this.tableData);

      const rebateAccountHeader = [
        'home.Account',
        'home.Name',
        'common.keys.DATE',
        'report.rebate.column.instrument',
        'home.TOTAL_VOLUME',
        'report.rebate.column.notionalvalue',
        'home.Rebate'
      ].map(key => this.$t(key));
      //rabateAccount 全量数据
      const tradeHistory = await this.queryAllRebateDetails();
      let option = {
        fileName,
        datas: [
          {
            sheetData: this.parseExcelData(this.tableData),
            sheetName: 'rebate',
            sheetHeader: rebateHeader,
            columnWidths: this.getColumnWidths()
            //宽度
          },
          {
            sheetData: tradeHistory,
            sheetName: 'account rebate',
            sheetHeader: rebateAccountHeader,
            columnWidths: [5, 7, 6, 6, 7, 8, 8]
            //宽度
          }
        ]
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
      return Promise.resolve();
    },
    //处理列表数据
    parseExcelData(tableData) {
      return tableData.map(item => {
        const { name, number, type } = item;

        const result = {
          name,
          number,
          type: this.$platform.accountTypeMaps(this.regulator)[type]
        };

        this.tableHeaderSeq.forEach(field => {
          const value = item[field] || { volume: 0, rebate: 0 }; // Default if field is missing
          result[`Volume(${field})`] = value.volume;
          result[`Rebate(${field})`] = value.rebate;
        });

        result.totalrebate = item.totalrebate;

        return result;
      });
    },

    //获取多列详情数据
    queryAllRebateDetails() {
      const mt4AccountSum = this.tableData.map(item => item.number).join(',');
      return apiRebatePayerTradeDetails({
        agentAccount: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        mt4AccountSum: mt4AccountSum,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(res => {
        return res.data.tradeHistory.map(item => {
          const { accountNumber, name, closeDate, symbol, volume, notionalValue, commission } = item;
          return {
            accountNumber,
            name,
            closeDate: moment(closeDate).format('YYYY-MM-DD'),
            symbol,
            volume,
            notionalValue,
            commission
          };
        });
      });
    },
    queryRebateReport() {
      console.log(this.chooseNode, 9090);
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }
      this.updateTimeRecord = {
        startDate: this.startDate,
        endDate: this.endDate
      };
      return apiRebate_report({
        account: this.accountID,
        subRebateAccount: this.chooseNode?.mt4Account || '',
        startDate: this.startDate,
        endDate: this.endDate,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(response => {
        this.totalRebate = response.data.total;
        this.summaries = [];

        this.tableData = response.data.payerSummaries.map(item => {
          var objs = Object.keys(item).reduce((newData, key) => {
            let newKey = key.toLowerCase().replace(/\s+/g, '');
            newData[newKey] = item[key];
            return newData;
          }, {});
          return objs;
        });

        this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);
        this.sortData();

        for (let key in response.data.summaries) {
          const obj = response.data.summaries[key];
          let temp = key.toLowerCase().replace(/\s+/g, '');
          temp = this.$t('common.products.' + temp);
          let newCategory = {
            category: temp,
            commission: obj.commission,
            volume: obj.volume
          };

          this.summaries.push(newCategory);
        }
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'totalrebate';
      this.sorting.order = this.sorting.order || 'descending';

      // Helper function to get nested properties dynamically
      const getNestedValue = (obj, path) => {
        return path.split('.').reduce((value, key) => (value ? value[key] : undefined), obj);
      };

      this.tableData.sort((a1, a2) => {
        const val1 = getNestedValue(a1, this.sorting.column);
        const val2 = getNestedValue(a2, this.sorting.column);

        if (val1 === undefined || val2 === undefined) return 0;

        return this.sorting.order === 'descending' ? val2 - val1 : val1 - val2;
      });
    },

    queryInstruments() {
      if (!this.dataSourceId) {
        return;
      }

      //恢復預設
      this.instrumentOptions.forEach(i => {
        if (Array.isArray(i.children)) i.children = [];
      });

      apiGet_instruments({ dataSourceId: this.dataSourceId }).then(response => {
        Object.keys(response.data).forEach(item => {
          let tempKey = item.toLowerCase().replace(/\s+/g, '');

          let instrumentObj = {
            value: tempKey,
            label: this.$t('common.products.' + tempKey),
            children: []
          };

          this.instrumentOptions.push(instrumentObj);
        });

        for (let k in response.data) {
          let instrument = this.instrumentOptions.find(i => i.value == k.replace(/\s+/g, ''));
          if (instrument && Array.isArray(instrument.children)) {
            instrument.children = response.data[k].sort().map(prod => {
              return { value: prod, label: prod };
            });
          }
        }
      }, this.$handleError);
    },
    sortChange(column) {
      if (!column.column) {
        return;
      }
      this.sorting = { column: column.column.sortBy, order: column.order };
      this.sortData();
    },

    init() {
      this.queryInstruments();
      this.queryRebateReport();
    },
    showAllInstruments() {
      this.showAll = !this.showAll;
    },
    generateDynamicHeader(tableData) {
      const rebateHeader = [this.$t('home.Name'), this.$t('home.Account'), this.$t('common.keys.ACCTYPE')];

      const firstItem = tableData[0];
      this.tableHeaderSeq = [];

      Object.keys(firstItem).forEach(key => {
        const value = firstItem[key];

        if (value && typeof value === 'object' && 'volume' in value && 'rebate' in value) {
          rebateHeader.push(`${this.$t('home.TOTAL_VOLUME')}(${this.$t(`common.products.${key}`) || key})`);
          rebateHeader.push(`${this.$t('home.Rebate')}(${this.$t(`common.products.${key}`) || key})`);

          this.tableHeaderSeq.push(key);
        }
      });

      rebateHeader.push(this.$t('report.rebate.column.totalRebate'));

      return rebateHeader;
    },
    getColumnWidths() {
      // Common columns (Name, Account, ACCTYPE) are fixed-length
      const commonColumnCount = 3;

      const totalColumns = commonColumnCount + this.tableHeaderSeq.length * 2;
      const defaultWidth = 7;

      // Create an array with the default width for each column
      const columnWidths = Array(totalColumns).fill(defaultWidth);

      columnWidths[0] = 5;
      columnWidths[1] = 7;
      columnWidths[2] = 6;

      return columnWidths;
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    dataSourceId() {
      return this.$store.state.behavior.dataSourceId;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    regulator() {
      return this.$store.state.common.regulator;
    },
    columns() {
      const firstRow = this.displayData[0];
      const nestedColumns = [];

      if (firstRow) {
        Object.keys(firstRow).forEach(key => {
          const value = firstRow[key];

          if (value && typeof value === 'object' && 'volume' in value && 'rebate' in value) {
            nestedColumns.push({
              prop: key,
              label: key
            });
          }
        });
      }

      return nestedColumns;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/rebateReport.scss';
</style>
