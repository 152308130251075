<template>
  <el-dialog :visible="dialogVisible" bottom="30px" width="540px" @closed="onClose" @close="onClose">
    <div class="rebateReportDialog">
      <div class="title">
        <p>
          {{ $t('report.rebate.others.instrumentsTraded') }} {{ $t('common.keys.FROM') }}
          <span>{{ $parent.startDate | formatDate('DD/MM/YYYY') }}</span> {{ $t('common.keys.TO') }}
          <span>{{ $parent.endDate | formatDate('DD/MM/YYYY') }}</span>
        </p>
      </div>
      <div class="info">
        <el-row>
          <el-col :span="12">
            <span>{{ $t('report.rebate.column.name') }}</span>
            <p>{{ viewTradeDetail.name }}</p>
          </el-col>
          <el-col :span="12">
            <span>{{ $t('report.rebate.column.accountNumber') }}</span>
            <p>{{ viewTradeDetail.mt4Account }}</p>
          </el-col>
        </el-row>
      </div>

      <!-- 饼状图 -->
      <div class="echarts">
        <div id="donut" style="height: 100%; width: 100%;"></div>
      </div>

      <div class="table_content">
        <div class="title">
          <p>
            <span>{{ $t('common.keys.tradingHistory') }}</span>
          </p>
        </div>
        <div class="table_box">
          <el-table :data="pagesDataArr" height="100%" width="100%">
            <el-table-column :label="$t('report.rebate.column.date')" min-width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.closeDate | date('DD/MM/YYYY') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="symbol" :label="$t('report.rebate.column.instrument')"
              min-width="120"></el-table-column>
            <el-table-column :label="$t('report.rebate.column.volume').toUpperCase()">
              <template slot-scope="scope">
                <span>{{ scope.row.volume | formatNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('report.rebate.column.notionalvalue').toUpperCase()" min-width="100">
              <template slot-scope="scope">
                <span>
                  {{ '$ ' + $options.filters.currency(scope.row.notionalValue, '').slice(0, -3) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('report.rebate.column.rebate').toUpperCase()">
              <template slot-scope="scope">
                <span>$ {{ scope.row.commission | currency('') }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页器 -->
      <el-pagination small layout="prev,pager,next" :total="detailData.length" hide-on-single-page
        :page-size="pageCount" @current-change="handlePaginationChange">
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { apiPayer_trade_details } from '@/resource';
export default {
  props: {
    dialogVisible: Boolean,
    viewTradeDetail: Object
  },
  data() {
    return {
      detailData: [],
      donutChart: null,
      // 设置饼状图
      donutOptions: {
        color: this.$platform.chartColor,
        legend: {
          //可滚动翻页的图例
          type: 'scroll',
          bottom: '0',
          data: [],
          textStyle: {
            color: '#dde6e7',
            fontSize: '14'
          },
          height: 10,
          icon: 'circle',
          itemWidth: 15,
          itemHeight: 15,
          itemGap: 30,
          pageTextStyle: {
            color: '#fff',
          },
          pageIconColor: '#fff',
          pageIconInactiveColor: 'rgba(255,255,255,0.8)'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['55%', '69%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: '{b}\n{c}\n({d}%)',
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  color: '#dde6e7',
                  fontSize: '16',
                  fontStyle: 'normal'
                }
              }
            },
            labelLine: {
              //标志引导线
              normal: {
                show: false
              }
            },
            data: []
          }
        ]
      },
      noDonutChartData: true,
      // 分页数据
      pagesDataArr: [],
      pageCount: 6
    };
  },
  watch: {
    viewTradeDetail(viewTradeDetail) {
      this.queryTradingDetail(viewTradeDetail.mt4Account);
    }
  },
  mounted() {
    window.onresize = () => {
      this.donutChart.resize();
    };
  },
  methods: {
    queryTradingDetail(mt4Account) {
      this.detailData = [];
      apiPayer_trade_details({
        mt4Account: mt4Account,
        agentAccount: this.$parent.accountID,
        startDate: this.$parent.startDate,
        endDate: this.$parent.endDate,
        symbol:
          Array.isArray(this.$parent.selectedInstrument) && this.$parent.selectedInstrument.length == 2
            ? this.$parent.selectedInstrument[1]
            : null
      })
        .then(response => {
          let tradeHistoryArr = response.data.tradeHistory;
          // 饼状图
          this.donutChart = this.echarts.init(document.getElementById('donut'));
          this.donutChart.setOption(this.donutOptions);
          // 展示数据
          this.detailData = tradeHistoryArr || [];
          if (this.detailData.length <= this.pageCount) {
            this.pagesDataArr = this.detailData;
          } else {
            this.pagesDataArr = this.detailData.slice(0, this.pageCount);
          }
          // 饼状图数据
          let donutData = Array.from(new Set(this.detailData.map(d => d.symbol))).map(symbol => {
            return {
              name: symbol,
              value: 0
            };
          });
          this.detailData.forEach(data => {
            donutData.find(d => d.name === data.symbol).value += data.volume;
          });
          // 添加两位小数点
          donutData.forEach(d => {
            d.value = d.value.toFixed(2);
          });
          this.updateDonutChart(donutData);
        })
        .catch(err => {
          console.log(err);
          this.donutChart = this.echarts.init(document.getElementById('donut'));
          this.donutOptions.series[0].data = [];
          this.donutOptions.legend.data = [];
          this.donutChart.setOption(this.donutOptions);
        });
    },
    // 给饼状图添加数据
    updateDonutChart(data) {
      this.noDonutChartData = false;
      this.donutChart.clear();

      if (data.length === 0) {
        this.donutOptions.series[0].data = [];
        this.donutOptions.legend.data = [];
        this.noDonutChartData = true;
      } else {
        this.donutOptions.series[0].data = data;
        this.donutOptions.legend.data = data.map(s => s.name);
        this.donutChart.setOption(this.donutOptions);
        this.noDonutChartData = false;
      }
    },
    // 分页
    handlePaginationChange(page) {
      let count1 = page * this.pageCount;
      let count2 = (page - 1) * this.pageCount;
      let arr = [];
      if (this.detailData.length <= count1) {
        arr = [...this.detailData];
      } else {
        arr = [...this.detailData.slice(0, count1)];
      }
      arr.splice(0, count2);
      this.pagesDataArr = arr;
    },
    onClose() {
      this.$emit('update:dialogVisible', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/rebateReport/dialog.scss';
</style>
